import bikerouteService from '@/services/bike-route'

const userService = {}

userService.loginAdmi = function (params) {
    return bikerouteService.post('/loginAdmi', { 
        email: params.email,
        password: params.password,
        device: params.device
    },{
        headers: {
            'Accept': 'application/json'
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

userService.locationsAll = function (params) {
    return bikerouteService.get('/users/locationsAll', {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

export default userService