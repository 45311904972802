import bikerouteService from '@/services/bike-route'

const reportService = {}

reportService.reports = function (params) {
    return bikerouteService.get('/reports', {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

reportService.reportsAccepted = function (params) {
    return bikerouteService.get('/reportsAccepted', {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

reportService.reportsRejected = function (params) {
    return bikerouteService.get('/reportsRejected', {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

reportService.reportsUpdate = function (params) {
    return bikerouteService.post('/reportsUpdate', { 
        report_id: params.report_id,
        status: params.status
    },
    {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err;
    });
}

reportService.notificationSend = function (params) {
    return bikerouteService.post('/notificationSend', { 
        title: params.title,
        message: params.message
    },
    {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err.data;
    });
}

reportService.reports_statistics = function (params) {
    return bikerouteService.post('/reports/statistics', { 
        date_start: params.date_start,
        date_end: params.date_end
    },
    {
        headers: {
            'Accept': 'application/json',
            'Authorization': "Bearer "+params.token 
        }
    },).then(res => res.data)
    .catch((err) => {
        return err.data;
    });
}

export default reportService