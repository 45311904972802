<template>
  <div class="cont-all">
    <Login v-if="this.verLogin" @loginRpt="loginC($event)" />
    <Mapa v-if="this.verMapa" @mapaRpt="closeC($event)" />
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Mapa from './components/Mapa.vue'
import M from 'materialize-css';

export default {
  name: 'App',
  components: {
    Login, Mapa
  },
  data()
  {
    return{
      verMapa: false,
      verLogin: true
    }
  },
  mounted() {
    document.addEventListener('DOMContentLoaded', function() {
        M.AutoInit();
        //var elems = document.querySelectorAll('.collapsible');
        //var instances = M.Collapsible.init(elems, options);
        var elems = document.querySelectorAll('.fixed-action-btn');
        var instances = M.FloatingActionButton.init(elems, {
          direction: 'right',
          hoverEnabled: false
        });
        console.log(instances)
    });

    if(localStorage.token && localStorage.token !='null') {
      this.verMapa = true,
      this.verLogin = false
    }
  },
  methods:{
      loginC(rptLogin)
      {
        this.rptLogin=rptLogin;
        if(this.rptLogin === "1")
        {
          this.verMapa=true;
          this.verLogin=false;

          this.$swal({
            icon: 'success',
            title: 'Huancavia',
            text: '¡Bienvenido!',
          });

          setTimeout(() => {
            location.reload();
          }, 3000);
        }else{
          this.verMapa=false;
          this.verLogin=true;
        }
      },
      closeC(rptClose)
      {
        this.rptClose=rptClose;
        if(this.rptClose === "1")
        {
          this.verLogin=true;
          this.verMapa=false;
          localStorage.token = null;
          this.$swal({
            icon: 'success',
            title: 'Huancavia',
            text: '¡Sesión cerrada!',
          })
        }else{
          this.verMapa=false;
          this.verLogin=true;
        }
      },
  }
}
</script>

<style>
    @media only screen and (min-width: 600px){
        .cont-all{
            width: 100%;
            margin: auto;
        }
    }
</style>