import bikerouteService from '@/services/bike-route'

const routeService = {}

routeService.routes = function (params) {
    return bikerouteService.get('/routes?name='+params.name, { 
    }).then(res => res.data)
    .catch((err) => {
        console.error(err);
    });
}

routeService.routes_list = function () {
    return bikerouteService.get('/routes/list', { 
    }).then(res => res.data)
    .catch((err) => {
        console.error(err);
    });
}

export default routeService