<template>
    <div class="background_login">
        <div class="row center-align cont_login">
            <h1 class="animate__animated animate__backInDown">HuancaVía</h1>
             <div class="row">
                <div class="row">
                    <div class="input-field col s12">
                    <input id="email" type="email" class="validate" v-model="email">
                    <label for="email">Email</label>
                    </div>
                </div> 
                <div class="row">
                    <div class="input-field col s12">
                    <input id="password" type="password" class="validate" v-model="password">
                    <label for="password">Password</label>
                    </div>
                </div>
            </div>
            <p class="center-align">
                <button class="waves-effect waves-light btn-large green" @click="loginOld">Login</button>
            </p>
            <!--<button class="animate__animated animate__backInUp btn btn-danger col s12" @click="mLogin"><i class="far fa-envelope"></i> Gmail</button>-->
        </div>
    </div>
</template>

<script>
import userService from "@/services/user";

export default {
    data() {
        return {
          email: "",
          password: "",
        }
    },
    methods: {
        async mLogin() {
            const googleUser = await this.$gAuth.signIn();
            var email = googleUser.it.Tt;
            var userId = googleUser.it.sT;

            if(email && userId)
            {
                var params = {
                    email: email,
                    password: userId,
                    device: "desktop"
                };
                userService.loginAdmi(params).then((res) => {
                    if(res.login.status==="error")
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Lo sentimos...',
                            text: '¡Error de credenciales!',
                        })
                        this.$emit('loginRpt','0')
                    }else{
                        localStorage.token = res.login.token
                        this.$swal({
                            icon: 'success',
                            title: 'Huancavia',
                            text: '¡Bienvenido!',
                        })
                        this.$emit('loginRpt','1')
                    }
                });  
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Lo sentimos...',
                    text: '¡Error!',
                })
            }

            
        },
        loginOld() {
            var params = {
                email: this.email,
                password: this.password,
                device: "desktop"
            };
            userService.loginAdmi(params).then((res) => {
                console.log(res);

                if(res.status==="error")
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Lo sentimos...',
                        text: '¡Error de credenciales!',
                    })
                    this.$emit('loginRpt','0')
                }else{
                    localStorage.token = res.token
                    this.$emit('loginRpt','1')
                }
            });  
        },
    },

}
</script>


<style>
    .background_login{
        background-image: url("https://previews.123rf.com/images/arija07/arija071804/arija07180400002/98549437-carrera-de-bicicletas-de-fondo-ilustraci%C3%B3n-deportiva.jpg");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        height: 100vh;
    }
    .cont_login{
        background-color: rgba(240, 248, 255,0.5);
        padding: 20% 0 0 0 !important;
        height: 100vh;
        width: 50%;
    }
    h1{
        font-family: 'Birthstone', cursive;
        font-size: 80px;
        font-weight: bold;
    }
    
    @media only screen and (max-width: 600px){
        .login{
            padding: 30% 0 70% 0;
        }
        .cont_login{
            width: 100%;
        }
    }
</style>