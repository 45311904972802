import { createApp } from 'vue'
import App from './App.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import GAuth from 'vue3-google-oauth2';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'materialize-css/dist/css/materialize.min.css'
import 'material-design-icons/iconfont/material-icons.css'

const gAuthOptions = { clientId: '862449416022-terupds7m0c05nnlc3q0nerl8pe1c48t.apps.googleusercontent.com', scope: 'profile email', prompt: 'consent', fetch_basic_profile: false }

const app = createApp(App);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAR3-5vOrZbhDHVi_x-KF0TfKDiZKQGN4E',
        libraries: "places"
    },
}).use(GAuth, gAuthOptions).use(VueSweetalert2).mount('#app')