<template>
  <div class="all_mapa">
   <div class="row mb-0 red accent-4 white-text map_title">
      <button class="col s2 btn red lighten-2" @click="refreshPage">
       <i class="fas fa-sync-alt"></i>
     </button>
     <div class="col s8 center-align">
        <h2 class="animate__animated animate__backInDown">HuancaVía</h2>
     </div>
     <button class="col s2 btn red" @click="close">
       <i class="fas fa-door-open"></i>
     </button>
   </div>
    <div class="row mb-0">
      <div class="input-field col s10">
        <select v-model="buscarRuta">
          <option v-for="option in list_routes2" v-bind:value="option.name" :key="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div class="input-field col s2 btn success" @click="search()">
        <i class="fas fa-search-location"></i>
      </div>
    </div> 

    <div class="input-field col s12" style="display: flex; justify-content: space-evenly; margin-top:0px;">
      <div v-for="option in list_routes" :key="option.id" class="btn teal darken-3" @click="search(option.name)">
          {{ option.name }}
      </div>
    </div>

    <div class="card-panel red lighten-5">
      Se encuentra {{this.latlng_ruta.people}} personas en la ruta.
    </div>
    <div class="">
      <div id="mapDiv" class="mapa_cont">
      </div>
    </div>

     <div class="fixed-action-btn">
        <a class="btn-floating btn-large red">
          <i class="fas fa-pencil-ruler"></i>
        </a>
        <ul>
          <li><a class="btn modal-trigger btn-floating blue" href="#modalNotification"><i class="fas fa-bell"></i></a></li>
          <li><a class="btn modal-trigger btn-floating red" href="#modal1" @click="modalSelec(3)"><i class="fas fa-book"></i></a></li>
          <li><a class="btn modal-trigger btn-floating green" href="#modal1" @click="modalSelec(2)"><i class="fas fa-book"></i></a></li>
          <li><a class="btn modal-trigger btn-floating yellow darken-3" href="#modal1" @click="modalSelec(1)"><i class="fas fa-book"></i></a></li>
          <li><a class="btn modal-trigger btn-floating grey darken-4" href="#modal_report"><i class="fas fa-book"></i></a></li>
        </ul>
      </div>

      <!-- Modal_1 -->
      <div id="modal1" class="modal">
        <div class="modal-content">
          <h4>Reportes</h4>
          
          <table class="highlight">
            <thead>
              <tr>
                  <th>Categoría</th>
                  <th>Descripción</th>
                  <th>Foto</th>
                  <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in valueModalSelec" v-bind:key="item.id">
                <td>{{item.category}}</td>
                <td>{{item.description}}</td>
                <td><a :href="'https://www.bike.farmacori.net/api/public/storage/'+item.photo" target="_blank">Foto</a></td>
                <td v-if="!item.attended">
                  <button class="btn" @click="updateReport(item.id,1)"><i class="fas fa-check"></i></button>
                  <button class="btn red" @click="updateReport(item.id,2)"><i class="fas fa-times"></i></button>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="modal-footer">
          <a href="#!" class="modal-close waves-effect waves-green btn-flat">Cerrar</a>
        </div>
      </div>
      <!-- Modal_1_end -->

      <!-- Modal_notification -->
      <div id="modalNotification" class="modal">
        <div class="modal-content">
          <h4>Enviar Notificación</h4>

          <div class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">title</i>
              <input id="icon_prefix" type="text" class="validate" v-model="value_title">
              <label for="icon_prefix">Título</label>
            </div>
            <div class="input-field col s12">
              <i class="material-icons prefix">message</i>
              <input id="icon_telephone" type="text" class="validate" v-model="value_message">
              <label for="icon_telephone">Mensaje</label>
            </div>
          </div>
          <button class="btn green right" @click="notificationSend()"> ENVIAR <i class="fas fa-share-square"></i></button>
          <a href="#!" class="modal-close waves-effect waves-green btn red right">Cerrar</a>
         
        </div>
        <div class="modal-footer">
        </div>
      </div>
      <!-- Modal_notification_end -->

      <!-- Modal_modal_report -->
      <div id="modal_report" class="modal" style="height: 400px;">
        <div class="modal-content">
          <h4>REPORTE</h4>

          <div class="row">
            <div class="input-field col s12 m6">
              <input id="send_date_start" type="date" class="validate" v-model="send_date_start">
              <label for="send_date_start">Fecha inicial</label>
            </div>
            <div class="input-field col s12 m6">
              <input id="send_date_end" type="date" class="validate" v-model="send_date_end">
              <label for="send_date_end">Fecha final</label>
            </div>
          </div>
          <button class="btn green right" @click="getStatistics()"> BUSCAR <i class="fas fa-share-square"></i></button>
          <a href="#!" class="modal-close waves-effect waves-green btn red right">Cerrar</a>
         
        </div>
        <div class="modal-footer">
        
          <table v-if="res_statistics_see" style="margin-top: 40px;">
            <thead>
              <tr>
                  <th>Total de reportes</th>
                  <th>Accidentes automovilísticos ({{res_statistics.count_aa_porcen}}%)</th>
                  <th>Desperfecto en la ruta ({{res_statistics.count_dr_porcen}}%)</th>
                  <th>Invade la ruta ({{res_statistics.count_ir_porcen}}%)</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{{res_statistics.total_reportes}}</td>
                <td>{{res_statistics.count_aa}}</td>
                <td>{{res_statistics.count_dr}}</td>
                <td>{{res_statistics.count_ir}}</td>
              </tr>
            </tbody>
          </table>
        
        </div>
      </div>
      <!-- Modal_modal_report_end -->

  </div>
</template>
<script>

import { Loader } from '@googlemaps/js-api-loader';
import routeService from "@/services/route";
import userService from "@/services/user";
import reportService from "@/services/report";

export default {
  data() 
  {
    return {
      ubicaciones: [],
      latlng_ruta: [],
      buscarRuta: "Ruta 1",
      map: [],
      loader: null,
      miUbicacion: [],
      refreshLocations: null,
      valueModalSelec: [],
      array_reports: [],
      array_reportsAccepted: [],
      array_reportsRejected: [],
      value_title: "",
      value_message: "",
      selected_route: "",
      send_date_start: "",
      send_date_end: "",
      res_statistics: [],
      res_statistics_see: false,
      list_routes: [],
      list_routes2: [
        { id: '1', name: 'Ruta 1' },
        { id: '2', name: 'Ruta 2' },
        { id: '3', name: 'Primer punto' },
        { id: '4', name: 'Segundo punto' },
        { id: '5', name: 'Tercer punto' }
      ]
    }
  },
  mounted() {
    if(!localStorage.token) {
      //window.location.href = "/"
      this.$emit('mapaRpt','0')
    }

    this.cargarReportes()

    if(localStorage.token)
    {
      this.refreshLocations = window.setInterval(() => {
        this.seeLocations()
      }, 5000)
    }
    
    setTimeout(() => {        
      routeService.routes_list().then((res) => {
        this.list_routes = res;
      });  
      routeService.routes({name: "2"}).then((res) => {
        this.latlng_ruta = res[0];
        this.drawRoute()
      });  
    }, 3000);
 
  },
  methods: {
    drawRoute (){
      this.loader = new Loader({
        apiKey: "AIzaSyAR3-5vOrZbhDHVi_x-KF0TfKDiZKQGN4E"
      });

      this.loader.load().then((google) => {
        var start = new google.maps.LatLng(this.latlng_ruta.start_lat, this.latlng_ruta.start_lng);
        var end = new google.maps.LatLng(this.latlng_ruta.end_lat, this.latlng_ruta.end_lng);
        
        var option ={
            zoom : 18,
            center : start 
        };
        this.map = new google.maps.Map(document.getElementById('mapDiv'),option);
        var display = new google.maps.DirectionsRenderer();
        var services = new google.maps.DirectionsService();

        if(this.latlng_ruta.busy == 1)
        {
          display.setOptions({
            polylineOptions: {
              strokeColor: 'red'
            }
          });
        }

        display.setMap(this.map);
            var request ={
                origin : start,
                destination:end,
                travelMode: 'DRIVING'
            };
            services.route(request,function(result,status){
                if(status =='OK'){
                    display.setDirections(result);
                }
            });

      }).catch(e => {
        alert(e)
      });
    },
    search(name) {
      var params;
      if(!name) {
        params = { name: this.buscarRuta };
      } else {
        params = { name: name };
      }
      
      routeService.routes(params).then((res) => {
        this.latlng_ruta = res[0];
        this.drawRoute()
      });  
    },
    seeLocations(){
      //mostrar ubicaciones
      this.loader.load().then((google) => {
        userService.locationsAll({token: localStorage.token}).then((res) => {
          if(res.data)
          {
            if(res.data.message === 'Unauthenticated.'){
              this.closeSession()
            }
          }

          this.ubicaciones = res;
          this.ubicaciones.forEach(element => {
            const image = "https://www.bike.farmacori.net/api/public/img/bici_25.png";
            var myLatlng = new google.maps.LatLng(element.lat,element.lng);
            var marker = new google.maps.Marker({
              position: myLatlng,
              title: "Ciclista",
              icon: image,
            });
            marker.setMap(this.map);
            setTimeout(() => {
              marker.setMap(null);
            }, 5000);
          });
        });
      }).catch(e => {
        alert(e)
      });
    },
    close() {
        delete localStorage.token
        clearInterval(this.refreshLocations)
        this.$emit('mapaRpt','1')
    },
    modalSelec($value) {
      if($value==1) this.valueModalSelec = this.array_reports
      if($value==2) this.valueModalSelec = this.array_reportsAccepted
      if($value==3) this.valueModalSelec = this.array_reportsRejected
    },
    closeSession() {
      delete localStorage.token
      clearInterval(this.refreshLocations)
      this.$emit('mapaRpt','0')
    },
    refreshPage() {
      location.reload();
    },
    cargarReportes(){

      reportService.reports({token: localStorage.token}).then((res) => {
        this.array_reports = res
      });  
      reportService.reportsAccepted({token: localStorage.token}).then((res) => {
        this.array_reportsAccepted = res
      });  
      reportService.reportsRejected({token: localStorage.token}).then((res) => {
        this.array_reportsRejected = res
      });  
    },
    updateReport(report_id, status){
      reportService.reportsUpdate({token: localStorage.token, report_id, status}).then((res) => {
        if(res.status === 'success'){
          this.$swal({
            icon: 'success',
            title: 'Actualizar reporte',
            text: '¡Reporte actualizado!',
          })
        } else {
          this.$swal({
            icon: 'error',
            title: 'Actualizar reporte',
            text: '¡Error!',
          })
        }
        setTimeout(() => {
          this.refreshPage()
        }, 3000);
      });
    },
    notificationSend(){
      reportService.notificationSend({token: localStorage.token, title: this.value_title, message: this.value_message}).then((res) => {
        if(res.status === 'success'){
          this.$swal({
            icon: 'success',
            title: 'Notificacón enviada!',
            text: '¡Reporte actualizado!',
          })
        } else {
          this.$swal({
            icon: 'error',
            title: 'Notificación no enviada!',
            text: '¡Error!',
          })
        }
        setTimeout(() => {
          this.refreshPage()
        }, 3000);
      });
    },
    getStatistics(){
      console.log(this.send_date_end)
      reportService.reports_statistics({token: localStorage.token, date_start: this.send_date_start, date_end: this.send_date_end}).then((res) => {
        if(res.status === 'success'){
          this.$swal({
            icon: 'success',
            title: 'Reportes!',
            text: res.message,
          })
          this.res_statistics = res
          if(res.message === 'Reporte actualizado.') this.res_statistics_see = true; else this.res_statistics_see = false;
        } else {
          this.$swal({
            icon: 'error',
            title: '¡Error!',
            text: 'Ingrese los campos fecha inicio y fecha final.',
          })
          this.res_statistics_see = false
        }
      });
    }
  }
}
</script>

<style>
  .all_mapa{
    margin: auto; 
    width:100%;
  }
  h2{
    font-family: 'Birthstone', cursive;
    font-size: 33px !important;
    font-weight: bold !important;
    margin: 0px !important;
    letter-spacing: 5px;
  }
  .mb-0{
    margin-bottom: 0px !important
  }
  .mapa_cont{
    position: relative;
    width: 100%; 
    height: 600px
  }
  .mapa_my{
    position: absolute;
    bottom: 50%;
    right: 5px;
  }
  .fixed-action-btn{
    bottom: 40px;
    left: 5px;
  }
  .gmnoprint{
    margin-top: -40px;
  }
  .map_title{
    padding: 5px;
  }
  @media only screen and (max-width: 1300px) and (min-width: 600px){
     .mapa_my{
      bottom: 50% !important;
      right: 34.6% !important;
    }
    .fixed-action-btn{
      bottom: 1% !important;
      left: 34% !important;
    }
  }
  @media only screen and (min-width: 1301px){
    .all_mapa{
      margin-top: 0% !important;
    }
     .mapa_my{
      bottom: 50% !important;
      right: 40% !important;
    }
    .fixed-action-btn{
      bottom: 1% !important;
      left: 1% !important;
    }
  }

</style>